// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

import { Storefront, Dashboard } from '@mui/icons-material';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const main: NavItemType = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/',
      icon: Dashboard,
    },
    {
      id: 'business',
      title: <FormattedMessage id="business" />,
      type: 'item',
      url: '/businesses',
      icon: Storefront,
      roles: ['admin', 'moderator']
    },
  ],
};

export default main;
