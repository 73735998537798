import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import DashboardLayout from "layout/Dashboard";

// render - dashboard
const DashboardPage = Loadable(lazy(() => import("pages/dashboard")));
const ServicesPage = Loadable(lazy(() => import("pages/services")));
const BrandsPage = Loadable(lazy(() => import("pages/brands")));
const CitiesPage = Loadable(lazy(() => import("pages/cities")));
const BusinessesPage = Loadable(lazy(() => import("pages/business")));
const BusinessPage = Loadable(lazy(() => import("pages/businessEditPage")));
const FilteredPagesListPage = Loadable(
  lazy(() => import("pages/filtered-pages")),
);
const FilteredPage = Loadable(lazy(() => import("pages/filteredPage")));
const BlogPostsPage = Loadable(lazy(() => import("pages/blog-posts")));
const BlogPostPage = Loadable(lazy(() => import("pages/blogPostPage")));
const BlogCategories = Loadable(lazy(() => import("pages/blog-categories")));
const FilteredPageCategories = Loadable(lazy(() => import("pages/filteredPage-categories")));

const NotFoundPage = Loadable(lazy(() => import("pages/maintenance/404")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <DashboardPage />,
        },
        {
          path: "/services",
          element: <ServicesPage />,
        },
        {
          path: "/cities",
          element: <CitiesPage />,
        },
        {
          path: "/brands",
          element: <BrandsPage />,
        },
        {
          path: "/businesses",
          element: <BusinessesPage />,
        },
        {
          path: "/filtered-pages",
          element: <FilteredPagesListPage />,
        },
        {
          path: "/filtered-pages-categories",
          element: <FilteredPageCategories />,
        },
        {
          path: "/blog-posts",
          element: <BlogPostsPage />,
        },
        {
          path: "/blog-categories",
          element: <BlogCategories />,
        },
      ],
    },
    {
      path: "/businesses/:id",
      element: <BusinessPage />,
    },
    {
      path: "/filtered-pages/:id",
      element: <FilteredPage />,
    },
    {
      path: "/blog-posts/:id",
      element: <BlogPostPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};

export default MainRoutes;
