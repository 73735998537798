import MapIcon from "@mui/icons-material/Map";

// type
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - PAGES ||============================== //

const tools: NavItemType = {
  id: "tools",
  type: "group",
  title: "Tools",
  roles: ['admin', 'moderator'],
  children: [
    {
      id: "map",
      title: "Карта додавання",
      type: "item",
      url: "/tools/locations.html",
      icon: MapIcon,
      external: true,
      target: true,
    },
  ],
};

export default tools;
