export enum LogEntityEnum {
  all = "all",
  filterPage = "filterPage",
  business = "business",
}

export const LogItemTitle = {
  address: "Адреса бізнесу",
  address_details: "Адреса бізнесу (детальніше)",
  brands: "Бренди",
  description: "Опис бізнесу",
  moderated: "Модерований бізнес",
  phones: "Номеа телефонів",
  properties: "Доступності",
  seoTitle: "Seo title",
  seoDescription: "Seo description",
  seoKeywords: "Seo keywords",
  services: "Вибрані послуги",
  socialLinks: "Соціальні мережі",
  subCategories: "Вибрані категорії",
  title: "Назва бізнесу",
  type: "Тип бізнесу",
  website: "Вебсайт",
  withoutSchedule: "Під запис",
  workSchedule: "Години роботи",
};

export const LogPageItemTitle = {
  brands: "Бренди",
  city: "Місто",
  description: "Опис сторінки",
  properties: "Доступності",
  seoTitle: "Seo title",
  seoDescription: "Seo description",
  seoKeywords: "Seo keywords",
  subCategories: "Вибрані категорії",
  services: "Вибрані послуги",
  slug: "URL адрес сторінки",
  title: "Назва сторінки",
};
