import axios, { AxiosRequestConfig } from 'axios';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080/cms' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('serviceToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401 && !window.location.href.includes('/login')) {
    //   window.location.pathname = '/login';
    // }
    console.log('error', error);
    if (error.response.status === 401 && !window.location.href.includes('/')) {
      window.location.pathname = '/';
      localStorage.removeItem('serviceToken');
      delete axiosServices.defaults.headers.common.Authorization;
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  },
);

export default axiosServices;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (args: string | [string, any] | [string, any, AxiosRequestConfig]) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, data, config);

  return res.data;
};

export const fetcherPut = async (args: string | [string, any] | [string, any, AxiosRequestConfig]) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.put(url, data, config);

  return res.data;
};

export const fetcherPatch = async (args: string | [string, any] | [string, any, AxiosRequestConfig]) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.patch(url, data, config);

  return res.data;
};

export const fetcherDelete = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.delete(url, config);

  return res.data;
};
